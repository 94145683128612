import { CommonModule } from '@angular/common';
import { Component, ElementRef, OnInit } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { TippyCloseOnClickOutsideDirective } from '../../../../../../src/app/modules/directives/tippy-close-on-click-outside.directive';
import { TippyComponentInstance } from '../../../../../../src/app/components/shared/enum';
import { RoutePathResolverService } from '../../../../../../src/app/modules/services/route-path-resolver.service';
import { NgxTippyService } from 'ngx-tippy-wrapper';
import { GsConnectService } from '../../stand-alone/gs-connect/services/gs-connect.service';
import { GsConnectNotificationItem, GsConnectPostItem } from '../../../../../../../goldstar-share/src/app/api-data/ng-openapi-gen-next/models';
import { GsConnectPostTypeEnum, PostNotificationType } from '../../stand-alone/gs-connect/models/gs-connect.model';
import { PostDatePipe } from '../../../../../../../goldstar-share/src/app/components/shared/post-date/post-date.pipe';

@Component({
	standalone: true,
	imports: [CommonModule, RouterModule, TippyCloseOnClickOutsideDirective, PostDatePipe],
	selector: 'app-gs-connect-notification',
	templateUrl: './gs-connect-notification.component.html',
	styleUrls: ['./gs-connect-notification.component.scss'],
})
export class GsConnectNotificationComponent implements OnInit {
	notificationList: GsConnectNotificationItem[] = [];
	postNotificationType: typeof PostNotificationType = PostNotificationType;
	gsConnectPostType: typeof GsConnectPostTypeEnum = GsConnectPostTypeEnum;
	gsConnectPostTypeList: string[] = [];
	constructor(
		private elementRef: ElementRef,
		private tippyService: NgxTippyService,
		private router: Router,
		private routePathResolverService: RoutePathResolverService,
		public gsConnectService: GsConnectService
	) {}

	ngOnInit() {
		this.gsConnectPostTypeList = Object.values(this.gsConnectPostType).filter((t) => t != GsConnectPostTypeEnum.EVENT);
		this.notificationList = this.gsConnectService.postNotificationList.value.map((t) => {
			return {
				...t,
				notificationMessage: this.getMessage(t),
			};
		});
	}
	public getMessage(item: GsConnectNotificationItem) {
		switch (item.notificationType) {
			case PostNotificationType.POST:
				if (item.gsConnectPostTypeSystemCode == GsConnectPostTypeEnum.POST) {
					return 'has added a new Post';
				} else if (item.gsConnectPostTypeSystemCode == GsConnectPostTypeEnum.EVENT) {
					return 'has posted an Event';
				} else if (item.gsConnectPostTypeSystemCode == GsConnectPostTypeEnum.BIRTHDAY) {
					return 'is celebrating Birthday';
				} else if (item.gsConnectPostTypeSystemCode == GsConnectPostTypeEnum.ANNIVERSARY) {
					return 'is celebrating Anniversary';
				} else if (item.gsConnectPostTypeSystemCode == GsConnectPostTypeEnum.NOTEWORTHY_NEWS) {
					return 'has posted a Noteworthy News';
				} else if (item.gsConnectPostTypeSystemCode == GsConnectPostTypeEnum.EMPLOYEE_RECOGNITION) {
					return 'has posted an Employee Recognition';
				} else if (item.gsConnectPostTypeSystemCode == GsConnectPostTypeEnum.RECENT_EVENT) {
					return 'has posted a Recent Event';
				}
				return '';
			case PostNotificationType.COMMENT:
				if (item.parentPostTypeSystemCode == GsConnectPostTypeEnum.POST) {
					return 'commented on your post';
				} else if (item.parentPostTypeSystemCode == GsConnectPostTypeEnum.EVENT) {
					return 'commented on your event post';
				} else if (item.parentPostTypeSystemCode == GsConnectPostTypeEnum.BIRTHDAY) {
					return 'commented on your Birthday Post';
				} else if (item.parentPostTypeSystemCode == GsConnectPostTypeEnum.ANNIVERSARY) {
					return 'commented on your Anniversary Post';
				} else if (item.parentPostTypeSystemCode == GsConnectPostTypeEnum.COMMENT) {
					return 'replied to your comment';
				} else if (item.parentPostTypeSystemCode == GsConnectPostTypeEnum.NOTEWORTHY_NEWS) {
					return 'commented on your Noteworthy News post';
				} else if (item.parentPostTypeSystemCode == GsConnectPostTypeEnum.EMPLOYEE_RECOGNITION) {
					return 'commented on your Employee Recognition post';
				} else if (item.parentPostTypeSystemCode == GsConnectPostTypeEnum.RECENT_EVENT) {
					return 'commented on your Recent Event post';
				}
				return '';
			case PostNotificationType.REACTION:
				if (item.gsConnectPostTypeSystemCode == GsConnectPostTypeEnum.POST) {
					return 'reacted to your post';
				} else if (item.gsConnectPostTypeSystemCode == GsConnectPostTypeEnum.EVENT) {
					return 'reacted to your event';
				} else if (item.gsConnectPostTypeSystemCode == GsConnectPostTypeEnum.BIRTHDAY) {
					return 'reacted to your Birthday Post';
				} else if (item.gsConnectPostTypeSystemCode == GsConnectPostTypeEnum.ANNIVERSARY) {
					return 'reacted to your Anniversary Post';
				} else if (item.gsConnectPostTypeSystemCode == GsConnectPostTypeEnum.COMMENT) {
					return 'reacted to your comment';
				} else if (item.gsConnectPostTypeSystemCode == GsConnectPostTypeEnum.NOTEWORTHY_NEWS) {
					return 'reacted on your Noteworthy News post';
				} else if (item.gsConnectPostTypeSystemCode == GsConnectPostTypeEnum.EMPLOYEE_RECOGNITION) {
					return 'reacted on your Employee Recognition post';
				} else if (item.gsConnectPostTypeSystemCode == GsConnectPostTypeEnum.RECENT_EVENT) {
					return 'reacted on your Recent Event post';
				}
				return '';

			default:
				return '';
		}
	}
	async openGsConnectPage(item?: GsConnectNotificationItem) {
		let gsConnectComponentGUID = this.routePathResolverService.resolveRouterUrlGUIDFromComponentName('GsConnectComponent');
		let gsConnectPostGUID: string = '';
		if (item) {
			if (
				(item.notificationType == this.postNotificationType.REACTION && item.gsConnectPostTypeSystemCode == GsConnectPostTypeEnum.COMMENT) ||
				(item.notificationType == this.postNotificationType.COMMENT && item.parentPostTypeSystemCode == GsConnectPostTypeEnum.COMMENT)
			) {
				let parentPost = await this.gsConnectService.fetchParentPost(item.gsConnectPostGUID ?? '');
				if (parentPost.isSuccess) {
					gsConnectPostGUID = parentPost.data?.gsConnectPostGUID ?? '';
				}
			} else if (item.notificationType == this.postNotificationType.COMMENT) {
				gsConnectPostGUID = item.parentGsConnectPostGUID ?? '';
			} else {
				gsConnectPostGUID = item.gsConnectPostGUID ?? '';
			}
		}
		if (gsConnectPostGUID) {
			this.gsConnectService.postNotificationList.next([]);
			this.tippyService.hide(TippyComponentInstance.GsConnect);
			this.router.navigate(['', gsConnectComponentGUID], { queryParams: { gsConnectPostGUID: gsConnectPostGUID } });
		} else {
			this.gsConnectService.postNotificationList.next([]);
			this.tippyService.hide(TippyComponentInstance.GsConnect);
			this.router.navigate(['', gsConnectComponentGUID]);
		}
	}
	close() {
		this.tippyService.hide(TippyComponentInstance.GsConnect);
		// const userHistory = document.getElementsByClassName('user-history-container')[0];
		// userHistory?.classList?.remove('pos-relative');
		// const favComponent = document.getElementsByClassName('favorite-container')[0];
		// favComponent?.classList?.remove('pos-relative');
	}
}
